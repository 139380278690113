"use client";

import { clsx } from "clsx";
import type { ForwardRefExoticComponent, ForwardedRef, InputHTMLAttributes, ReactNode, RefAttributes } from "react";
import { forwardRef, useId } from "react";
type WithFloatingLabelProps = InputHTMLAttributes<HTMLInputElement> & {
  readonly ref?: ForwardedRef<HTMLInputElement>;
  readonly children?: ReactNode;
};
export function withFloatingLabel(Component: ForwardRefExoticComponent<Omit<WithFloatingLabelProps, "ref"> & RefAttributes<HTMLInputElement>>) {
  function ComponentWithFloatingLabel({
    placeholder,
    children,
    className,
    ...rest
  }: WithFloatingLabelProps, ref: ForwardedRef<HTMLInputElement>) {
    const inputId = useId();
    return <div className={className} data-sentry-component="ComponentWithFloatingLabel" data-sentry-source-file="WithFloatingLabel.component.tsx">
        <Component className={clsx(className, "peer placeholder:text-transparent")} id={inputId} placeholder={placeholder ?? ""} ref={ref} {...rest} data-sentry-element="Component" data-sentry-source-file="WithFloatingLabel.component.tsx" />
        <label className={clsx("pointer-events-none absolute left-0 top-1 origin-top-left -translate-y-full scale-75 select-none whitespace-nowrap py-0.25 text-lg text-dim transition-transform ease-linear motion-reduce:transition-none lg:text-base", "peer-focus:not(:read-only):left-0 peer-focus::not(:read-only):max-w-none peer-focus::not(:read-only):-translate-y-full peer-focus:not(:read-only)::scale-75 peer-focus:not(:read-only):peer-placeholder-shown:top-1 peer-focus:not(:read-only):peer-placeholder-shown:scale-75 peer-focus:not(:read-only):peer-placeholder-shown:py-0.25 peer-focus:not(:read-only):peer-placeholder-shown:text-dim", "peer-placeholder-shown:left-1 peer-placeholder-shown:top-1/2 peer-placeholder-shown:max-w-full peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:truncate peer-placeholder-shown:bg-transparent peer-placeholder-shown:text-nobel", "peer-required:after:inline-block peer-required:after:align-text-top peer-required:after:text-sm peer-required:after:leading-none peer-required:after:text-error-400 peer-required:after:content-['*']")} htmlFor={inputId}>
          {placeholder}
        </label>

        {children}
      </div>;
  }
  return forwardRef<HTMLInputElement, WithFloatingLabelProps>(ComponentWithFloatingLabel);
}